import Swiper from 'swiper';
import 'inputmask/dist/jquery.inputmask.js';
import "@fancyapps/fancybox";
import 'simplebar';

$('document').ready(function(){
  $('.preloader').addClass('hide');
});



$('.play-video').click(function(){
  $('.video-screen').addClass("show");
  $('#video').get(0).play();
  if ("ontouchstart" in document.documentElement) {
    $('body, html').addClass('disable-scroll');
  } else {
    disableScroll();
  }
});

$('.video-container__close').click(function(){
  $('.video-screen').removeClass("show");
  $('#video').trigger('pause');
  if ("ontouchstart" in document.documentElement) {
    $('body, html').removeClass('disable-scroll');
  } else {
    enableScroll();
  }
});

$('.form-btn').click(function(e){
  e.preventDefault();
  $('.consultation').addClass("open");
  $('.consultation__close').addClass("active");
  $('.header__controlls').addClass('hidden');
  if ("ontouchstart" in document.documentElement) {
    $('body, html').addClass('disable-scroll');
  } else {
    disableScroll();
  }
});

$('.consultation__close').click(function(){
  $('.consultation').removeClass("open");
  $('.consultation__close').removeClass("active");
  $('.header__controlls').removeClass('hidden');
  if ("ontouchstart" in document.documentElement) {
    $('body, html').removeClass('disable-scroll');
  } else {
    enableScroll();
  }
});

// function doOnOrientationChange()
//   {
//     switch(window.orientation) 
//     {  
//       case -90:
//       case 90:
        
//           if ("ontouchstart" in document.documentElement) {
//             $('.mobile-display').addClass('d-none');
//           } 
      
//         break; 
//       default:
        
//           if ("ontouchstart" in document.documentElement) {
//             $('.mobile-display').removeClass('d-none');
//           } 
        
//         break; 
//     }
//   }
 
//   window.addEventListener('orientationchange', doOnOrientationChange);
 
//   // Initial execution if needed
//   doOnOrientationChange();

// disable scroll start
var keys = {37: 1, 38: 1, 39: 1, 40: 1};

function preventDefault(e) {
  e = e || window.event;
  if (e.preventDefault)
      e.preventDefault();
  e.returnValue = false;  
}

function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
    }
}

function disableScroll() {
  if (window.addEventListener) // older FF
      window.addEventListener('DOMMouseScroll', preventDefault, false);
  document.addEventListener('wheel', preventDefault, {passive: false}); // Disable scrolling in Chrome
  window.onwheel = preventDefault; // modern standard
  window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
  window.ontouchmove  = preventDefault; // mobile
  document.onkeydown  = preventDefaultForScrollKeys;
}

function enableScroll() {
    if (window.removeEventListener)
        window.removeEventListener('DOMMouseScroll', preventDefault, false);
    document.removeEventListener('wheel', preventDefault, {passive: false}); // Enable scrolling in Chrome
    window.onmousewheel = document.onmousewheel = null; 
    window.onwheel = null; 
    window.ontouchmove = null;  
    document.onkeydown = null;  
}
// disable scroll end

$(document).ready(function(){
    $(".lang-switch").each(function(){
        const current = $(this).find('.lang-switch__current-lang');
        const currentEl = $(this).find('.current-lang a').html();
        current.html(currentEl);
    });

});

var BrandsSwiper = new Swiper('.brands-swiper', {
  slidesPerView: 1,
  effect: 'fade',
  navigation: {
      prevEl: '.swiper-button-next',
      nextEl: '.swiper-button-prev',
  }
});

var CategorySwiper1 = new Swiper('.category-swiper-1', {
  slidesPerView: 1,
  effect: 'fade',
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
});

var CategorySwiper2 = new Swiper('.category-swiper-2', {
  slidesPerView: 1,
  effect: 'fade',
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
});

var CategorySwiper3 = new Swiper('.category-swiper-3', {
  slidesPerView: 1,
  effect: 'fade',
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
});

var CategorySwiper4 = new Swiper('.category-swiper-4', {
  slidesPerView: 1,
  effect: 'fade',
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
});

var CategorySwiper5 = new Swiper('.category-swiper-5', {
  slidesPerView: 1,
  effect: 'fade',
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
});

var ProductSwiper = new Swiper('.product-swiper', {
    slidesPerView: 1,
    effect: 'fade',
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
});

var CollectionSwiper = new Swiper('.collection-swiper', {
    slidesPerView: 2,
    spaceBetween: 30,
    navigation: {
        prevEl: '.swiper-button-next',
        nextEl: '.swiper-button-prev',
    },
    breakpoints: {
        576: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      }
});

$(document).ready(function(){

  $('.filter-btn').click(function(){
    if($(this).hasClass('active')) {
      $('.filter-wrap').slideUp(200);
      $(this).removeClass("active");
    } else {
      $('.filter-wrap').slideDown(200);
      $(this).addClass("active");

    }
  });

});

$(document).ready(function(){
  $('.btn-menu').click(function(){
    if($(this).hasClass('active')){

      $(this).removeClass('active');
      $('.menu').removeClass('open');
      $('.nav').removeClass('active');
      $('.header').removeClass('header--black');

      if ("ontouchstart" in document.documentElement) {
        $('body, html').removeClass('disable-scroll');
      } else {
        enableScroll();
      }
    } else {
      
      $(this).addClass('active');
      $('.menu').addClass('open');
      $('.nav').addClass('active');
      $('.header').addClass('header--black');
      
      if($('.favorites').length > 0) {
        $('.favorites').removeClass('active');
        $('.favourites').removeClass('open');
      }

      if ("ontouchstart" in document.documentElement) {
        $('body, html').addClass('disable-scroll');
      } else {
        disableScroll();
      }
    }
  });
});

$(document).ready(function(){
  $('.favorites').click(function(){
    if($(this).hasClass('active')){

      $(this).removeClass('active');
      $('.favourites').removeClass('open');
      $('.header').removeClass('header--black');

      if ("ontouchstart" in document.documentElement) {
        $('body, html').removeClass('disable-scroll');
      } else {
        enableScroll();
      }
    } else {

      $(this).addClass('active');
      $('.favourites').addClass('open');
      $('.header').addClass('header--black');

      if($('.btn-menu').length > 0) {
        $('.btn-menu').removeClass('active');
        $('.menu').removeClass('open');
        $('.nav').removeClass('active');
      }
      if ("ontouchstart" in document.documentElement) {
        $('body, html').addClass('disable-scroll');
      } else {
        disableScroll();
      }
    }
  });
});

$(document).ready(function(){
  $('.phone-mask').inputmask({mask: "+38 (999) 99 - 99 - 999", showMaskOnHover: false}); 
  $('.data-mask').inputmask({mask: "99/99/9999", showMaskOnHover: false}); 
  $('.time-mask').inputmask({mask: "99.99", showMaskOnHover: false}); 
});

$('.product-swiper a').fancybox({
  keyboard: true,
  buttons: [
    "zoom",
    "close"
  ],
  animationEffect: "fade",
  transitionEffect: "fade",

  btnTpl: {
    zoom:
      '<button data-fancybox-zoom class="fancybox-button fancybox-button--zoom" title="{{ZOOM}}">' +
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.7 17.3l-3-3a5.9 5.9 0 0 0-.6-7.6 5.9 5.9 0 0 0-8.4 0 5.9 5.9 0 0 0 0 8.4 5.9 5.9 0 0 0 7.7.7l3 3a1 1 0 0 0 1.3 0c.4-.5.4-1 0-1.5zM8.1 13.8a4 4 0 0 1 0-5.7 4 4 0 0 1 5.7 0 4 4 0 0 1 0 5.7 4 4 0 0 1-5.7 0z"/></svg>' +
      "</button>",

    close:
      '<button data-fancybox-close class="fancybox-button fancybox-button--close" title="{{CLOSE}}">' +
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 10.6L6.6 5.2 5.2 6.6l5.4 5.4-5.4 5.4 1.4 1.4 5.4-5.4 5.4 5.4 1.4-1.4-5.4-5.4 5.4-5.4-1.4-1.4-5.4 5.4z"/></svg>' +
      "</button>",

    // Arrows
    arrowLeft:
      '<button data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="{{PREV}}">' +
      '<div><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11.28 15.7l-1.34 1.37L5 12l4.94-5.07 1.34 1.38-2.68 2.72H19v1.94H8.6z"/></svg></div>' +
      "</button>",

    arrowRight:
      '<button data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="{{NEXT}}">' +
      '<div><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.4 12.97l-2.68 2.72 1.34 1.38L19 12l-4.94-5.07-1.34 1.38 2.68 2.72H5v1.94z"/></svg></div>' +
      "</button>",
    smallBtn:
      '<button type="button" data-fancybox-close class="fancybox-button fancybox-close-small" title="{{CLOSE}}">' +
      '<svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"/></svg>' +
      "</button>"
  },
});



